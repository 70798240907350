export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [3],
		"/blog": [~4],
		"/blog/[slug]": [~5],
		"/guide": [6],
		"/guide/accnotfound": [7],
		"/guide/accphnotfound": [8],
		"/guide/phlogin": [9],
		"/guide/xlogin": [10],
		"/login": [11],
		"/manage/blog": [12],
		"/manage/blog/add": [14],
		"/manage/blog/[id]": [13],
		"/manage/email": [15],
		"/manage/tickets": [16],
		"/manage/tickets/[id]": [17],
		"/manage/users": [~18],
		"/manage/waitlist": [19],
		"/panel": [20,[2]],
		"/panel/accounts": [21,[2]],
		"/panel/accounts/add": [25,[2]],
		"/panel/accounts/[id]": [22,[2]],
		"/panel/accounts/[id]/finish": [23,[2]],
		"/panel/accounts/[id]/report": [24,[2]],
		"/panel/plans": [~26,[2]],
		"/panel/profile": [27,[2]],
		"/panel/referrals": [28,[2]],
		"/panel/tickets": [29,[2]],
		"/panel/tickets/add": [31,[2]],
		"/panel/tickets/[id]": [30,[2]],
		"/privacy": [32],
		"/signin": [33],
		"/signout": [34],
		"/terms": [35]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';